const rootDomain = 'unthread.io';
const publicDomain = `app.${rootDomain}`;
const internalDomain = `unthread.${rootDomain}`;
const assetsBaseUrl = `https://assets.${rootDomain}/assets`;

export const environment = {
  production: true,
  environment: 'prd',
  rootDomain,
  host: publicDomain,
  baseUrl: `https://${publicDomain}`,
  internalBaseUrl: `https://${internalDomain}`,
  assetsBaseUrl,
  firebase: {
    apiKey: 'AIzaSyChom1_nPXBUMcLPy7kflpDbYO6yYfTogo',
    authDomain: 'trnout-co.firebaseapp.com',
    databaseURL: 'https://trnout-co.firebaseio.com',
    projectId: 'trnout-co',
    storageBucket: 'trnout-co.appspot.com',
    messagingSenderId: '388022759474',
    appId: '1:507797187790:web:f177732a72828030d0749f',
    measurementId: 'G-Y4LNCMQXYL',
  },
  googleClientId: '507797187790-shg9lue7j204ejkag4uetb0c76rqa6c7.apps.googleusercontent.com',
  googleAnalyticsTrackingId: 'UA-146910747-2',
  useEmulators: false,
  slack: {
    oauthURL: 'https://slack.com/oauth/v2/authorize',
    clientID: '4035768323312.3958510788662',
    appID: 'A03U6F0P6KG',
    scopes:
      'assistant:write,pins:write,pins:read,app_mentions:read,channels:history,channels:join,channels:manage,channels:read,channels:write.invites,channels:write.topic,chat:write,chat:write.customize,commands,dnd:read,groups:history,groups:read,groups:write,groups:write.invites,groups:write.topic,im:history,im:read,im:write,mpim:history,mpim:read,reactions:write,reactions:read,team:read,users.profile:read,users:read,users:read.email,usergroups:read,usergroups:write,files:write,files:read,emoji:read',
    userScopes:
      'channels:read,channels:write,channels:history,groups:read,groups:write,groups:history,chat:write,team:read,files:write,files:read,reactions:write,reactions:read,users:read,users:read.email',
    requiredScopes: [
      'app_mentions:read',
      'channels:history',
      'channels:read',
      'chat:write',
      'chat:write.customize',
      'commands',
      'groups:history',
      'groups:read',
      'im:history',
      'im:read',
      'mpim:history',
      'mpim:read',
      'reactions:write',
      'reactions:read',
      'team:read',
      'users.profile:read',
      'users:read',
      'users:read.email',
      'usergroups:read',
      'usergroups:write',
      'pins:write',
    ],
    requiredUserScopes: ['channels:read', 'channels:write', 'groups:read', 'groups:write', 'chat:write'],
  },
  mixPanelToken: '9becc5a36989e6e3c5ed9a0c5cd38e61',
  stripePlans: {
    free: {
      productId: 'prod_OHVdBTlvp3su7I',
      priceId: 'price_1NUwcPGCefNJLANDMCegOUBp',
    },
    basic: {
      productId: 'prod_MpGxbJoP3UTsLg',
      priceIdMax10: 'price_1NUwwMGCefNJLANDjf5fDGmR',
      priceIdMax25: 'price_1PTOiZGCefNJLANDHxiVWkYI',
      priceIdMax50: 'price_1PTOi0GCefNJLAND8kinVqvd',
    },
    pro: {
      productId: 'prod_OHW0SbUabX8tKL',
      priceIdMax10: 'price_1NUwyzGCefNJLANDxhVNMZXj',
      priceIdMax25: 'price_1NtBlIGCefNJLANDBpd6XvYW',
      priceIdMax50: 'price_1PTOrzGCefNJLANDrjMmZx86',
    },
  },
  nangoPublicKey: '9513828d-0d2d-4fcc-8986-fca40a9f78b1',
};
